import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
    clearWorkout,
    fetchWorkout,
    playerOnPause,
    playerOnPlay
} from "../soloSlice"
import useHeight from "../../videochat/hooks/useHeight/useHeight"
import StyledSnackbar from "../../../components/StyledSnackbar"
import BackdropLoader from "../../../components/BackdropLoader"
import clsx from "clsx"
import { navigate } from "@reach/router"
import MuxDataTracker from "../../analytics/MuxDataTracker"
import { WORKOUTS_HOME } from "../../routing/Locations"
import { BGBlurImage } from "../../../components/BGBlurImage"
import MuxPlayer, { MuxPlayerRefAttributes } from '@mux/mux-player-react';
import { Helmet } from "react-helmet"
import { getUserProfile } from "../../../utils"
import { FadeInReview } from "../../review/ReviewWorkout"
import BackButton from "../../../components/BackButton"


const useStyles = makeStyles((theme) => ({
    root: {
        outline: "none",
        width: "100%",
        display: "flex",
        height: "100vh",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        overflow: "hidden"
    },
    player: {
        zIndex: 2,
        width: "100%",
        height: "100%",
    },
    feedbackActive: {
        [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            height: "auto",
            overflowY: "auto",
            alignContent: "start",
            alignItems: "start",
            margin: theme.spacing(2, 0)
        }
    },
    ":&focus": {
        outline: "none",
        border: "none"
    }
}))

export interface Props {
    id: string
    timeSlotId?: string
}

export default function MuxSoloPlayer(props: Props) {
    const classes = useStyles()
    const muxPlayerRef = useRef<MuxPlayerRefAttributes>(null);
    const soloState = useSelector((state: RootState) => state.solo)
    const dispatch = useDispatch()

    const height = useHeight()

    useEffect(() => {
        dispatch(fetchWorkout({ wid: props.id, timeSlotId: props.timeSlotId }))
    }, [props.id])

    useEffect(() => {
        const play = soloState.playVideo
        play ? muxPlayerRef.current?.play() : muxPlayerRef.current?.pause()
    }, [soloState.playVideo])

    useEffect(() => {
        return () => {
            dispatch(clearWorkout())
        }
    }, [])

    const rootStyle = clsx({
        [classes.root]: true,
        [classes.feedbackActive]: soloState.showFeedback
    })

    const muxProps = soloState.userWorkout?.muxProps;

    function playerWithTracker() {
        if (muxProps != null) {
            return <>
                <Helmet
                    script={[{ src: "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1" }]} />
                <MuxPlayer
                    className={classes.player}
                    ref={muxPlayerRef}
                    playbackId={muxProps.playbackId}
                    tokens={{
                        playback: muxProps.playbackToken,
                        thumbnail: muxProps.thumbnailToken,
                        storyboard: muxProps.storyboardToken
                    }}
                    poster={soloState.userWorkout!.workout.thumbnailUrl}
                    metadata={{
                        video_id: `${soloState.userWorkout!.workout.id}`,
                        video_title: `${soloState.userWorkout!.workout.title}`,
                        viewer_user_id: `${getUserProfile()?.uid ?? "unknown"}`,
                    }}
                    streamType="on-demand"
                    autoPlay={false}
                    onPlay={() => {
                        dispatch(playerOnPlay())
                    }}
                    onPause={() => dispatch(playerOnPause())}
                />
            </>
        }
        return <>
            <Helmet
                script={[{ src: "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1" }]} />
            <MuxDataTracker wid={soloState.userWorkout!.workout.id} streamType={"on-demand"}
                playerName={"solo"} videoTitle={soloState.userWorkout!.workout.pageTitle} muxStream={soloState.userWorkout!.muxStream} />
            <MuxPlayer
                className={classes.player}
                ref={muxPlayerRef}
                src={`${soloState.userWorkout!.streamLink}`}
                poster={soloState.userWorkout!.workout.thumbnailUrl}
                metadata={{
                    video_id: `${soloState.userWorkout!.workout.id}`,
                    video_title: `${soloState.userWorkout!.workout.title}`,
                    viewer_user_id: `${getUserProfile()?.uid ?? "unknown"}`,
                }}
                streamType="on-demand"
                autoPlay={false}
                onPlay={() => {
                    dispatch(playerOnPlay())
                }}
                onPause={() => dispatch(playerOnPause())}
            />
        </>
    }

    return <div className={rootStyle} style={{ height: height }}>
        <BackdropLoader show={soloState.loading} />
        {soloState.userWorkout && <BGBlurImage imageUrl={soloState.userWorkout?.workout.thumbnailUrl}
            localImage={soloState.userWorkout?.workout.localImage} />}
        {soloState.showVideo && playerWithTracker()}

        {/* {soloState.showWaitingRoom &&
            <ClassWaitOverlay workout={soloState.userWorkout!.workout} showPlay={soloState.showWaitRoomPlay ?? false}
                countdown={soloState.countdown}
                numHausmates={0}
                playClicked={() => { dispatch(waitRoomPlayClicked()) }} />} */}

        <FadeInReview show={soloState.showFeedback} workoutId={soloState.userWorkout?.workout.id ?? ""} isGroup={false} />
        {soloState.error && <StyledSnackbar show={true} severity={"error"} message={soloState.error.message} />}

        <BackButton clicked={() => navigate(WORKOUTS_HOME)} />


    </div>
}
