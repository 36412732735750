import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { HoverTapEffect } from "./AnimationEffects"
import { ArrowBack } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: theme.spacing(1.75),
    left: theme.spacing(1.75),
    width: "30px",
    height: "30px",
    zIndex: theme.zIndex.drawer + 2,
    "&:hover": {
      cursor: "pointer",
      color: "white"
    }
  }
}))

export interface Props {
  clicked: () => void
}

export default function BackButton(props: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root} onClick={props.clicked}>
      <HoverTapEffect>
        <ArrowBack fontSize="large"/>
      </HoverTapEffect>
    </div>
  )
}
